/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'

import Play from 'img/play.inline.svg'
import Close from 'img/close.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import LazyLoad from 'react-lazyload'

const StyledUspWithVideo = styled.div``

const StyledImage = styled(Plaatjie)`
  @media screen and (max-width: 991px) {
    min-height: 40px;
    min-width: 40px;
  }
`

const Title = styled(ParseContent)`
  & h2 {
    color: rgba(0, 0, 0, 0.15);
    font-weight: ${(props) => props.theme.font.weight.m};
  }
`

const Video = styled.iframe`
  width: calc(100% - 60px);
  height: calc(100% - 80px);
  border: none;
  outline: none;
  border-radius: 14px;
  position: absolute;
  top: 50px;
  left: 30px;

  @media screen and (max-width: 576px) {
    width: calc(100% - 30px);
    height: calc(100% - 60px);
    left: 15px;
    top: 45px;
    border-radius: 27px;
  }
`

const Content = styled(ParseContent)`
  & p {
    font-weight: ${(props) => props.theme.font.weight.xl};
    line-height: 19px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 400px) {
    & p {
      font-size: ${(props) => props.theme.font.size[14]};
      line-height: 16px;
    }
  }

  @media screen and (max-width: 350px) {
    & p {
      font-size: ${(props) => props.theme.font.size[12]};
      line-height: 14px;
    }
  }
`

const ThumbNail = styled(motion.div)`
  border: 1px solid #ececec;
  border-radius: 29px;
  position: relative;
  cursor: pointer;
`

const ThumbNailImage = styled(Plaatjie)`
  border-radius: 29px;
`

const PlayIcon = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 9px;
  position: absolute;
  height: 100px;
  width: 100px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  & svg {
    stroke: ${(props) => props.theme.color.face.light};
  }
`

const VideoContainer = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const VideoModal = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  height: 440px;
  width: 640px;
  border-radius: 29px;
  position: relative;
  padding: 50px 30px 30px 30px;
  cursor: default;

  @media screen and (max-width: 576px) {
    width: calc(100% - 30px);
    padding: 15px;
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 30px;
  top: 12px;
  cursor: pointer;

  & svg {
    width: 30px;
    height: 30px;
    stroke-width: 3px;
  }

  @media screen and (max-width: 991px) {
    top: 10px;
    right: 15px;
  }
`

interface UspWithVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UspWithVideo
}

const UspWithVideo: React.FC<UspWithVideoProps> = ({ fields }) => {
  const [videoShow, setVideoShow] = useState(false)

  return (
    <StyledUspWithVideo className="pt-4 pb-5">
      <AnimatePresence>
        {videoShow && (
          <VideoContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setVideoShow(!videoShow)}
          >
            <VideoModal onClick={(e) => e.stopPropagation()}>
              <CloseIcon onClick={() => setVideoShow(!videoShow)}>
                <Close />
              </CloseIcon>
              <LazyLoad offset={800} once>
                <Video
                  width="100%"
                  height="100%"
                  src={fields.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </LazyLoad>
            </VideoModal>
          </VideoContainer>
        )}
      </AnimatePresence>

      <div className="container">
        <Title content={fields.description} />
        <div className="row pt-lg-5 pt-3">
          <div className="col-lg-5">
            <div className="row px-lg-2 px-md-5">
              {fields?.usp?.map((point, index: number) => (
                <div className="col-4 pb-4" key={index}>
                  <div className="p-md-3 p-2">
                    <StyledImage loading="eager" alt="" image={point?.image} />
                  </div>
                  <Content content={point?.description} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-7 pt-lg-0 pt-5 pl-lg-5">
            <ThumbNail
              onClick={() => setVideoShow(!videoShow)}
              whileHover="hover"
            >
              <ThumbNailImage loading="eager" alt="" image={fields.image} />
              <PlayIcon
                variants={{
                  hover: { scale: 1.1 },
                }}
              >
                <Play />
              </PlayIcon>
            </ThumbNail>
          </div>
        </div>
      </div>
    </StyledUspWithVideo>
  )
}
export default UspWithVideo
